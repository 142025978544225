import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import '../styles/main.scss';

export default ({ withSidebar = true, children }) => {
    return (
        <div className="ie-html">
            <Header />
            <main className="ie-body">
                <div className="ie-content">{children}</div>
                {withSidebar && (
                    <div className="ie-sidebar">
                        <Sidebar />
                    </div>
                )}
            </main>
        </div>
    );
};
