/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

export default () => (
    <header>
        <nav className="ie-nav">
            <div>
                <a className="ie-nav-logo" href="/" />
                <h1>
                    <a href="/">Игорь Еремеев</a>
                </h1>
                <div className="ie-nav-subtitle">Персональный блог</div>
            </div>

            <div className="ie-social-icons">
                <a className="ie-social-vk" href="https://vk.com/virsen76">
                    Я ВКонтакте
                </a>
                <a className="ie-social-rss" href="/rss.xml">
                    RSS
                </a>
            </div>
        </nav>
    </header>
);
