import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Formik, Form, Field } from 'formik';
import md5 from 'md5';

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Введите email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Введите правильный email';
    }

    return errors;
};

export default () => {
    const [subscribed, setSubscribed] = useState(false);

    const data = useStaticQuery(graphql`
        query LastPostsQuery {
            allMdx(
                sort: { fields: [frontmatter___date], order: DESC }
                filter: { frontmatter: { public: { eq: true } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            date(formatString: "D MMMM YYYY года", locale: "ru")
                            title
                            path
                            thumbnail {
                                childImageSharp {
                                    fixed(width: 100, height: 75, quality: 60, cropFocus: CENTER) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const posts = data.allMdx.edges.map(edge => ({
        date: edge.node.frontmatter.date,
        title: edge.node.frontmatter.title,
        path: edge.node.frontmatter.path,
        thumbnailFixed:
            edge.node.frontmatter.thumbnail && edge.node.frontmatter.thumbnail.childImageSharp
                ? edge.node.frontmatter.thumbnail.childImageSharp.fixed
                : null,
    }));

    const subscribe = async (values, form) => {
        const url = 'https://service.igor-eremeev.com/mailchimp';
        const salt = 'j4hfl6';

        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: values.email,
                hash: md5(values.email + salt),
            }),
        });

        const { status, message } = await response.json();

        if (status !== 'success') {
            form.setFieldError('email', message || 'Непредвиденная ошибка');
        } else {
            form.resetForm();
            setSubscribed(true);
        }
    };

    return (
        <div>
            <div className="ie-photo" />
            <h2>О блоге</h2>
            <p>
                Мы с семьей переехали из России в США в 2013 году. Здесь в блоге история нашей
                адаптации и просто примечательные моменты жизни в Северной Каролине. Отличный способ
                сохранить впечатления и поделиться ими с людьми.
            </p>

            <h2>Подписка</h2>
            <p>Введите свой email, если хотите получать на него обновления блога.</p>
            <Formik initialValues={{ email: '' }} onSubmit={subscribe} validate={validate}>
                {({ errors, touched, isSubmitting }) => (
                    <Form
                        onChange={() => {
                            if (subscribed) {
                                setSubscribed(false);
                            }
                        }}
                    >
                        {subscribed && (
                            <div className="alert alert-success">
                                Вы успешно подписаны на обновления блога
                            </div>
                        )}
                        <div className="form-group">
                            <Field
                                className={`form-control ${
                                    errors.email && touched.email ? 'is-invalid' : ''
                                }`}
                                name="email"
                                type="email"
                                placeholder="Email"
                                autoComplete="off"
                            />
                            <div className="is-invalid invalid-feedback">{errors.email}</div>
                        </div>
                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    style={{ marginRight: '0.5em' }}
                                />
                            )}
                            Подписаться
                        </button>
                    </Form>
                )}
            </Formik>

            <h2>Последние записи</h2>
            {posts.map(post => (
                <div key={post.path} className="ie-sidebar-link">
                    {post.thumbnailFixed ? (
                        <div className="ie-sidebar-thumbnail">
                            <Img fixed={post.thumbnailFixed} style={{ display: 'block' }} />
                        </div>
                    ) : (
                        <div className="ie-sidebar-thumbnail ie-sidebar-thumbnail-placeholder" />
                    )}

                    <div>
                        <a href={post.path}>{post.title}</a>
                        <div className="ie-sidebar-date">{post.date}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};
